import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
const FrontStories = () => {
    const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark(
            filter: {
              frontmatter:{
               shortTitle: {
                in: ["it-consultant-closure","clothing-wholesaler-liquidation","construction-company"]
              }
              }
            }
            limit:3
          ) {
            edges {
                node {
                    snippet
                    frontmatter {
                        title
                        image {
                            childImageSharp {
                                fluid(maxWidth: 404, maxHeight: 280, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        
                    }
                    
                    }
                    fields {
                        slug
                    }
                    excerpt(pruneLength:200)
                }
            }
        }
    }
    `)

    ////////// Manually change the order of the stories on the homepage ................................/// Potential breaking code in the future///
    const stories = data.allMarkdownRemark.edges;
    var finalStories = [];
    finalStories.push(stories[0]);
    finalStories.push(stories[2]);
    finalStories.push(stories[1]);


    return (
        <section className="section front-stories">
            <div className="container">

                <div className="columns">
                    <div className="column is-12 no-padding-top">
                        <h2 className="news-title is-size-4">
                            Real Client Stories
						</h2>
                        <div className="columns">
                            {finalStories.map((edge) => {
                                return (
                                    <div className="column is-4">
                                        <article>
                                            <header className="stories-header">
                                                <div className="image-thumbnail content">
                                                    <Link to={edge.node.fields.slug}>
                                                        <Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
                                                    </Link>
                                                </div>
                                                <p className="post-meta content home-stories-title-box">
                                                    <Link className="title is-size-4" to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
                                                </p>
                                            </header>
                                            <p className="insights-blurb">
                                                <div className="snippet" dangerouslySetInnerHTML={{ __html: edge.node.snippet }} />
                                            </p>
                                        </article>
                                    </div>
                                )
                            })}



                        </div>


                    </div>
                </div>

            </div>
        </section>


    )
}

export default FrontStories;