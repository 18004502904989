import React from 'react';
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faArchive, faCoins, faUserFriends, faBuilding, faUserAltSlash } from '@fortawesome/free-solid-svg-icons'
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

const boxStyle = {
    textAlign: "center",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
};

const subtitleStyle = {
    marginTop: "1.2rem",
    color: "#222e50",
    fontSize: "1.5rem"
};

const ServiceTiles = () => (

    <section className="service-tiles letterbox section" >

        <div className="container">
            <div className="service-tile-intro">
                <h2>Services</h2>
                <p>It can be hard to figure out what the next steps are when a business is facing difficulties, we have collected some of the most common issues
                    in the articles below. We understand that every situation is different, for a tailored solution for your business, please get in touch</p>
            </div>
            <div className="tile is-ancestor">
                <Link class="tile is-parent service-tile" to="/creditors-voluntary-liquidation">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box" style={boxStyle}>
                        <FontAwesomeIcon icon={faCoins} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle" style={subtitleStyle}>Creditors’ Voluntary Liquidation</p>
                        <p className="service-excerpt">
                            If your Company’s finances have reached the point where a rescue or restructuring solution is unlikely to be possible,
                            it is usually most appropriate to place the Company into Creditors’ Voluntary Liquidation (also known as CVL).
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>
                    </article>
                </Link>
                <Link className="tile is-parent service-tile" to="/administration">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box">
                        <FontAwesomeIcon icon={faFileAlt} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle">Administration</p>
                        <p className="service-excerpt">
                            Administration provides immediate protection against action being taken by Company creditors such as your suppliers or HMRC.
                            This is important as it buys you time to determine the best strategy for you and your business.
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>
                    </article>
                </Link>
                <Link className="tile is-parent service-tile" to="company-voluntary-arrangement">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box">
                        <FontAwesomeIcon icon={faBuilding} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle">Company Voluntary Arrangement</p>
                        <p className="service-excerpt">
                            A Company Voluntary Arrangement (or CVA as they are commonly known) is a legal contract between your Company and its creditors where the
                            Company typically pays a monthly payment based on what its cash flow forecasts say it can afford.
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>

                    </article>
                </Link>



            </div>
            <div className="tile is-ancestor">
                <Link class="tile is-parent service-tile" to="/how-to-close-your-company">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box">
                        <FontAwesomeIcon icon={faArchive} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle">How to close your company</p>
                        <p className="service-excerpt">
                            If you are a Director wanting to close your Limited Company, the appropriate method of closure is entirely dependent on your Company's circumstances.
                            Our step by step guide explains all of the options for you.
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>

                    </article>
                </Link>
                <Link className="tile is-parent service-tile" to="/members-voluntary-liquidation">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box">
                        <FontAwesomeIcon icon={faUserFriends} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle">Members' Voluntary Liquidation</p>
                        <p className="service-excerpt">
                            Sometimes a Company has run its course or the shareholders simply want to bring it to a close and move on with another venture.
                            An MVL is a form or solvent liquidation which enables shareholders to extract value in a tax efficient manner.
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>

                    </article>
                </Link>


                <Link className="tile is-parent service-tile" to="/redundancy">
                    <div className="tile-overlay"><h3>Find Out More...</h3></div>
                    <article className="tile is-child box">
                        <FontAwesomeIcon icon={faUserAltSlash} size={"5x"} color={"#17a9bf"} />
                        <p className="subtitle">Redundancy</p>
                        <p className="service-excerpt">
                            For many Companies that have been trading for a long time or have a large number of staff,
                            one of the biggest barriers to returning to profitability is the inability to downsize due to prohibitively large redundancy costs.
                            <br /><br /><span className="mobile-info">Click for more</span>
                        </p>

                    </article>
                </Link>

            </div>

        </div>


    </section >
);


export default ServiceTiles;