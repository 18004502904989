import React from 'react'
import { Link } from 'gatsby'
import Helmet from '../components/helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import LetterBoxCall from '../components/letterbox-call'
import ServiceTiles from '../components/service-tiles'
import FrontStories from '../components/front-client-stories'


const NotFoundPage = () => (
  <div>
    <Helmet />
    <Header bannerText="Something went wrong..." />
    <section className="section">
      <div className="container content">

        <div class="notification">

          <p>We're really sorry it appears the resource or page you were looking for cannot be found, you can head back to the homepage <Link to="/">here</Link></p>
        </div>

      </div>
    </section>
    <ServiceTiles />
    <FrontStories />
    <LetterBoxCall />
    <Footer />
  </div>
)

export default NotFoundPage
